import CmsAddButton from "@/components/cms/cmsAddButton/cmsAddButton";
import CmsGeneralConfigFrame from "@/components/cms/cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import PbContentButton from "@/components/content/pbContentButton/pbContentButton";
import PbContentImage from "@/components/content/pbContentImage/pbContentImage";
import PbContentRichText from "@/components/content/pbContentRichText/pbContentRichText";
import CardsContentElementStyleGlobal from "@/components/contentelements/cardsContentElement/cardsContentElementStyleGlobal";
import CardsContentElementStyleScoped from "@/components/contentelements/cardsContentElement/cardsContentElementStyleScoped";
import ConditionalRender from "@/components/util/conditionalRender/conditionalRender";
import useCardsSettings from "@/hooks/useCardsSettings";
import useCesStrButton from "@/hooks/useCesStrButton";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import useScopedClassName from "@/hooks/useScopedClassName";
import {
  ceSettingById,
  getNestedCeSettingsData,
  valueFromStoreSetting,
} from "@/services/ceSettings/ceSettingsService";
import {
  moveInArrayAction,
  removeFromArrayAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsAddNestedContentElementThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import {
  refreshRenderKey,
  showNestedConfigModal,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementCardsStoreSetting } from "@/types/ceSettings/ceSettings";
import { CECards } from "@/types/content-elements";
import { getContentElementName, getIdOrNewId } from "@/utils/util";
import clsx from "clsx";
import React, { useState } from "react";
import ContentElementWrapper from "../wrapper/contentElementWrapper";

export interface CardsContentElementProps {
  content: CECards;
  position: number;
  ceSettings?: ContentElementCardsStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const CARDS_CE_NAME = "cardsCE";

export default React.memo(function CardsContentElement(
  props: CardsContentElementProps
) {
  const CARDS_SCOPED_CLASS = useScopedClassName(
    CARDS_CE_NAME,
    props.content,
    props.position
  );
  const dispatch = useAppDispatch();
  const tCms = useCmsTranslation();
  const {
    cardsColumns,
    cardsColumnSpan,
    cardBackgroundColor,
    cardImageHeight,
    cardObjectFit,
    cardPosition,
  } = useCardsSettings(props.ceSettings);
  const { richTextClassName, richtextEditorSettings } = useCesStrRichText(
    props.ceSettings?.richtext!,
    props.ceSettings?.richtext
  );

  const { buttonClassName } = useCesStrButton(
    props.content.cfgStrInnerButtonButton,
    props.ceSettings?.button
  );
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const nestedElementAttributes = useAppSelector((state) => {
    return state.cmsEdit.data?.availableElements?.find(
      (element: any) => props.content.__component === element.uid
    )?.nestedElement;
  });
  const renderKey = useAppSelector((state) => state.cmsGeneral.renderKey);

  const [selectedCard, setSelectedCard] = useState<number>(-1);

  const maxCardsPerColumn = cardsColumns(props.content.cfgCardColumnsAmount);

  const { showConfirmModal } = useConfirmModal(
    () => {
      dispatch(
        removeFromArrayAction({
          attributePath: `draftPage.content[${props.position}].content`,
          position: selectedCard,
        })
      );
    },
    () => {
      setSelectedCard(-1);
    }
  );

  const handleOnCardDelete = (index: number) => {
    setSelectedCard(index);
    showConfirmModal({
      title: tCms("nestedContent-deleteContentElementConfirmation", {
        contentElement: tCms(getContentElementName(tCms("card"))),
      }),
      content: (
        <div>
          {tCms("nestedContent-deleteContentElementConfirmationLong", {
            contentElement: tCms(getContentElementName(tCms("card"))),
          })}
        </div>
      ),
      acceptBtnText: tCms("delete"),
      denyBtnText: tCms("cancel"),
      xIsDeny: true,
      icon: "circle-exclamation-light",
    });
  };

  const handleMove = (index: number, direction: "up" | "down") => {
    dispatch(
      moveInArrayAction({
        attributePath: `draftPage.content[${props.position}].content[${index}]`,
        direction: direction,
      })
    );
    dispatch(refreshRenderKey());
  };

  const handleOnNestedConfigModalOpen = (index: number) => {
    dispatch(
      showNestedConfigModal({
        position: props.position,
        nestedPosition: index,
        contentElementSetting: ceSettingById(
          valueFromStoreSetting(props.content.cfgStrContentElementSetting)
        ),
      })
    );
  };

  const hoverFloatingAnimation =
    !editView && props.content.cfgAnimationCardHover === "hover-floating";

  const zoomInAnimation =
    !editView && props.content.cfgAnimationCardHover == "hover-zoom-in";

  return (
    <>
      <ContentElementWrapper
        name={CARDS_CE_NAME}
        scopedClassName={CARDS_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div
          className={clsx(
            CARDS_CE_NAME,
            CARDS_SCOPED_CLASS,
            maxCardsPerColumn === 0 && "grid",
            maxCardsPerColumn > 0 && "row"
          )}
        >
          {props.content.content.map((card, index) => {
            return (
              <div
                className={clsx(
                  hoverFloatingAnimation && "hover-floating",
                  zoomInAnimation && "hover-img-zoom-in",
                  maxCardsPerColumn > 0 && [
                    "col-12",
                    `col-md-${cardsColumnSpan(maxCardsPerColumn, "md")}`,
                    `col-lg-${cardsColumnSpan(maxCardsPerColumn, "lg")}`,
                    `col-xl-${cardsColumnSpan(maxCardsPerColumn, "xl")}`,
                    `col-xxl-${cardsColumnSpan(maxCardsPerColumn, "xxl")}`,
                  ],
                  "pb-card-container"
                )}
                key={
                  getIdOrNewId(props.content, props.position) +
                  "-" +
                  index +
                  "-" +
                  renderKey
                }
              >
                <CmsGeneralConfigFrame
                  frameOnHover={true}
                  hideAddCEButton={true}
                  moveDirection={"horizontal"}
                  onDelete={() => handleOnCardDelete(index)}
                  position={index}
                  arrayLength={props.content.content.length}
                  moveUp={() => handleMove(index, "up")}
                  moveDown={() => handleMove(index, "down")}
                  openConfigModal={() => handleOnNestedConfigModalOpen(index)}
                >
                  <div
                    className="pb-card"
                    style={{
                      backgroundColor: cardBackgroundColor(
                        card.cfgStrBackgroundColor
                      ),
                    }}
                  >
                    {props.ceSettings?.showImage && (
                      <PbContentImage
                        cmsPosition={props.position}
                        cmsFieldImage={`content[${index}].img`}
                        height={cardImageHeight(
                          props.content.cfgImageMaxHeight
                        )}
                        width="100%"
                        isMobile={props.isMobile}
                        img={card.img}
                        objectFit={cardObjectFit(card.cfgCardImageObjectFit)}
                        objectPosition={cardPosition(
                          card.cfgCardImageObjectFit
                        )}
                        placeholderWidth={cardImageHeight(
                          props.content.cfgImageMaxHeight
                        )}
                        placeholderHeight={cardImageHeight(
                          props.content.cfgImageMaxHeight
                        )}
                        widthLimitSettings={
                          cardImageHeight(props.content.cfgImageMaxHeight) * 3
                        }
                      />
                    )}
                    <div className="pb-card-content row">
                      <div className="col">
                        <PbContentRichText
                          className={richTextClassName}
                          ceSettings={getNestedCeSettingsData(
                            props.ceSettings?.richtext!
                          )}
                          content={card.richTextContent}
                          position={props.position}
                          cmsFieldContent={`content[${index}].richTextContent`}
                        />
                      </div>
                    </div>
                    <ConditionalRender
                      condition={Boolean(card.linkText && card.linkUrl)}
                      alwaysRenderInEditView={true}
                    >
                      <div className="pb-card-actions row">
                        <div className="col pb-card-button">
                          <PbContentButton
                            className={buttonClassName}
                            url={card.linkUrl}
                            text={card.linkText}
                            cmsPosition={props.position}
                            cmsFieldText={`content[${index}].linkText`}
                            getParams={card.linkGetParams}
                            hrefLang={card.linkHrefLang}
                            rel={card.linkRel}
                            target={card.linkTarget}
                            anchorName={card.linkAnchor}
                            cmsFieldGetParams={`content[${index}].linkGetParams`}
                            cmsFieldRel={`content[${index}].linkRel`}
                            cmsFieldTarget={`content[${index}].linkTarget`}
                            cmsFieldHrefLang={`content[${index}].linkHrefLang`}
                            cmsFieldUrl={`content[${index}].linkUrl`}
                            cmsFieldAnchorName={`content[${index}].linkAnchor`}
                          />
                        </div>
                      </div>
                    </ConditionalRender>
                  </div>
                </CmsGeneralConfigFrame>
              </div>
            );
          })}
          {editView && (
            <div className={clsx(`col-12`)}>
              <CmsAddButton
                title={tCms("cards-addCard")}
                text={tCms("cards-addCard")}
                onClick={() => {
                  dispatch(
                    cmsAddNestedContentElementThunk(
                      nestedElementAttributes,
                      props.position,
                      "content",
                      props.content.content.length + 1
                    )
                  );
                }}
                maxWidth={350}
              />
            </div>
          )}
        </div>
      </ContentElementWrapper>
      <CardsContentElementStyleGlobal {...props} />
      <CardsContentElementStyleScoped
        {...props}
        scopedSelector={CARDS_SCOPED_CLASS}
      />
    </>
  );
});
