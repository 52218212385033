import {
  CARDS_CE_NAME,
  CardsContentElementProps,
} from "@/components/contentelements/cardsContentElement/cardsContentElement";

/**
 * This is the global style of a CE.
 * This style is applied on all instances of this CE
 *
 * @param props
 * @returns
 */
export default function CardsContentElementStyleGlobal(
  props: CardsContentElementProps
) {
  return (
    <style jsx global>
      {`
        :global(.${CARDS_CE_NAME}) {
        }
      `}
    </style>
  );
}
