import {
  determineStoreValueId
} from "@/services/ceSettings/ceSettingsService";
import {
  ContentElementCardsStoreSetting,
  StoreSetting,
} from "@/types/ceSettings/ceSettings";
import { STORE_COLOR_CLASS_PREFIX } from "@/utils/constants";

export default function useCardsSettings(
  ceSettings: ContentElementCardsStoreSetting | undefined
) {
  const cardBackgroundColor = (cardBackgroundColorSetting: StoreSetting) => {
    const cardBgColorId = determineStoreValueId(
      cardBackgroundColorSetting,
      ceSettings?.bgColorsCards
    );

    return `var(--${STORE_COLOR_CLASS_PREFIX}${cardBgColorId})`;
  };

  const cardObjectFit = (cfgObjectFit: string) => {
    if (cfgObjectFit?.startsWith("cover")) {
      return "cover";
    }
    return cfgObjectFit;
  };

  const cardPosition = (cfgObjectFit: string) => {
    switch (cfgObjectFit) {
      case "coverTop":
        return "top";
      case "coverBottom":
        return "bottom";
      default:
        return "unset";
    }
  };

  const cardImageHeight = (cfgImageMaxHeight: string) => {
    return ceSettings?.imageHeight[cfgImageMaxHeight] ?? 200;
  };

  const cardsColumns = (cfgCardColumnsAmount: string) => {
    const lastIndex = cfgCardColumnsAmount.lastIndexOf("-");
    if (lastIndex !== -1) {
      return parseInt(cfgCardColumnsAmount.substring(lastIndex + 1));
    }
    return 0;
  };

  const cardsColumnSpan = (cardsColumns: number, breakpoint: string) => {
    const columnSpan = 12 / cardsColumns;
    switch (breakpoint) {
      case "md":
        return columnSpan < 6 ? 6 : columnSpan;
      case "lg":
        return columnSpan < 4 ? 4 : columnSpan;
      case "xl":
        return columnSpan < 3 ? 3 : columnSpan;
      case "xxl":
      default:
        return columnSpan;
    }
  };

  return {
    cardBackgroundColor,
    cardObjectFit,
    cardPosition,
    cardsColumnSpan,
    cardsColumns,
    cardImageHeight,
  };
}
