import PbRichTextView from "@/components/pbRichTextView/pbRichTextView";
import { useAppSelector } from "@/store/store";
import { ContentElementRichTextStoreSetting } from "@/types/ceSettings/ceSettings";
import dynamic from "next/dynamic";
const CmsContentRichText = dynamic(
  () =>
    import("@/components/cms/cmsContent/cmsContentRichText/cmsContentRichText")
);

export interface PbContentRichTextProps {
  /**
   * Toggle between edit view and preview in edit mode
   */
  disableEditView?: boolean;
  /**
   * The html string representing the rich text
   */
  content: string;
  /**
   * A placholder text
   */
  placeholder?: string;
  /**
   * Shows the toolbar below the text field, default is above
   */
  toolbarBelow?: boolean;
  /**
   * CE position
   */
  position?: number;
  /**
   * Content to element in content array
   */
  cmsFieldContent?: string;
  /**
   * "cesstr-richtext-{id}" where {id} is the id of a contentElementSetting
   */
  className: string;
  /**
   * CE settings
   */
  ceSettings: ContentElementRichTextStoreSetting;
}

/**
 * PbContentRichText
 *
 * Wrapper for CEs to show either edit or user view
 */
export default function PbContentRichText(props: PbContentRichTextProps) {
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  return (
    <>
      {editView && !props.disableEditView ? (
        <CmsContentRichText {...props} />
      ) : (
        <PbRichTextView
          htmlString={props.content}
          className={props.className}
        />
      )}
    </>
  );
}
