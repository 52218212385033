import {
  getNestedCeSettingsData
} from "@/services/ceSettings/ceSettingsService";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import useStrButton from "./useStrButton";

export default function useCesStrButton(
  buttonStoreSetting?: StoreSetting,
  ceSettings?: StoreSetting
) {

  const nestedCeSettings = getNestedCeSettingsData(
    ceSettings
  )?.buttons;

  return useStrButton(buttonStoreSetting, nestedCeSettings)
}
