import { useAppSelector } from "@/store/store";
import React from "react";

interface ConditionalRenderProps {
  children: React.ReactNode;
  condition?: boolean;
  alwaysRenderInEditView?: boolean;
}
/**
 * Renders if (condition) (&& alwaysRenderInEditView && editView) is true.
 * @param props
 * @returns
 */
const ConditionalRender = (props: ConditionalRenderProps) => {
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  return (
    <React.Fragment>
      {props.children &&
      ((props.alwaysRenderInEditView && editView) || props.condition) ? (
        <React.Fragment>{props.children}</React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default ConditionalRender;
